<template>
  <div class="page1">
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData();
        "
      >
        <el-form
          :inline="true"
          label-position="right"
          class="demo-form-inline"
          :model="formInline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="商家名称:">
                <el-input
                  placeholder="请输入商家名称"
                  v-model="formInline.storeName"
                />
              </el-form-item>
              <el-form-item label="最后修改时间:">
                <el-date-picker
                  v-model="timeArray"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </AuthorityComponent>
              <el-button icon="el-icon-delete" @click="reset">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                ComponentName="el-button"
                :permission="['button.create']"
                type="primary"
                @click="$router.push({ path: '/storeManagerAE' })"
                >创建商户
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%; font-size: 10px"
          :default-sort="{ prop: 'updatedTime', order: 'descending' }"
          @sort-change="sortMethod"
        >
          <el-table-column label="商家ID" align="center" prop="storeId" />
          <el-table-column label="商家编码" align="center" prop="storeCode" />
          <el-table-column label="商家名称" align="center" prop="storeName" />
          <el-table-column
            :label="$t('searchModule.last_Operator')"
            align="center"
            prop="operatorName"
          />
          <el-table-column :label="$t('searchModule.state')" width="80" align="center">
            <template slot-scope="scope">
              <span
                ><i
                  :class="
                    scope.row.storeState == 1
                      ? 'el-icon-circle-check'
                      : 'el-icon-circle-close'
                  "
                  :style="{
                    color: scope.row.storeState == 1 ? '#67c23a' : '#f56c6c',
                  }"
                ></i
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.last_Change_time')"
            align="center"
            prop="updatedTime"
            sortable
          />
          <el-table-column
            label="操作"
            width="140"
            align="center"
            v-if="
              $route.meta.authority.button.invoiceSetting ||
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.manager
            "
          >
            <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                @command="handleCommand($event, scope.row)"
              >
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.detail']"
                    :command="1"
                    >查看
                  </AuthorityComponent>
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.enable']"
                    v-if="scope.row.storeState !== 1"
                    :command="2"
                    >启用
                  </AuthorityComponent>
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.disable']"
                    v-if="scope.row.storeState === 1"
                    :command="3"
                    >停用
                  </AuthorityComponent>
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.edit']"
                    :command="4"
                    >编辑
                  </AuthorityComponent>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <router-link :to="{path: '/merchantManagerAE', query: scope.row}"><el-button v-if='$route.meta.authority.button.edit' type="text">修改</el-button></router-link>
              <el-button v-if='$route.meta.authority.button.manager' type="text" @click="managerUser(scope.$index, scope.row)">管理用户</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as utils from "@/common/js/utils";
import { dateFormat, setIndex } from "@/common/js/public.js";
export default {
  name: "operatorInfoManage",
  data() {
    return {
      total: 0,
      pageSize: 10,
      pageNum: 1,
      loading: false,
      tableData: [],
      formInline: { selectType: 1 },
      timeArray: [],
      sort: "desc", // desc 降序 acs正序
    };
  },
  methods: {
    // 分页处理
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showDetail(index, obj) {
      this.$router.push({
        path: "/merchantManagerAE",
        query: obj,
      });
    },

    sortMethod(val) {
      if (val.order == "ascending") {
        this.sort = "asc";
      } else {
        this.sort = "desc";
      }
      this.pageNum = 1;
      this.searchData();
    },

    // 操作细则
    handleCommand(command, row) {
      switch (command) {
        case 1: {
          let parkNames = [];
          let parkIds = [];
          if ("storeParks" in row) {
            row.storeParks.forEach((item) => {
              parkNames.push(item.parkName);
              parkIds.push(item.parkId);
            });
          }
          this.$router.push({
            path: "/storeManageDetail",
            query: {
              ...row,
              parkNames: parkNames.join(","),
              parkIds: parkIds.join(","),
            },
          });
          break;
        }
        case 2:
          this.confirm(row.storeId, 1);
          break;
        case 3:
          this.confirm(row.storeId, 2);
          break;
        case 4: {
          let parkNames = [];
          let parkIds = [];
          if ("storeParks" in row) {
            row.storeParks.forEach((item) => {
              parkNames.push(item.parkName);
              parkIds.push(item.parkId);
            });
          }
          this.$router.push({
            path: "/storeManagerAE",
            query: {
              ...row,
              parkNames: parkNames.join(","),
              parkIds: parkIds.join(","),
            },
          });
          break;
        }
        default:
          break;
      }
    },
    confirm(storeId, storeState) {
      if (storeState == 2) {
        this.$confirm("停用后该商家将失效，是否确认停用该商家？", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.updateState(storeId, storeState);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        this.updateState(storeId, storeState);
      }
    },
    // 修改状态
    updateState(storeId, storeState) {
      this.loading = true;
      this.tableData = [];
      const tempData = { storeId, storeState };
      const opt = {
        url: "/acb/2.0/store/changeStatus",
        method: "post",
        data: tempData,
        success: (res) => {
          this.searchData();
        },
        fail: (res) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 管理用户
    // managerUser(index, obj) {
    //   this.$router.push({
    //     path: "/operatorAdministratorsManage",
    //     query: obj
    //   });
    // },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      const tempData = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.sort,
      };
      const { storeName } = this.formInline;
      tempData.storeName = storeName;
      if (this.timeArray && this.timeArray.length > 0) {
        tempData.startTime =
          this.$moment(this.timeArray[0]).format("YYYY-MM-DD") + " 00:00:00";
        tempData.endTime =
          this.$moment(this.timeArray[1]).format("YYYY-MM-DD") + " 23:59:59";
      }
      const opt = {
        url: "/acb/2.0/store/list",
        method: "get",
        data: tempData,
        success: (res) => {
          console.log("res-->", res);
          this.tableData = setIndex(this.pageNum, res.value.list);
          this.total = res.value.total * 1 || 0;
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    /* 商家名称的下拉框 */
    // getRoleList() {
    //   this.$axios.get("/acb/2.0/operation/nameList").then(res => {
    //     this.loading = false;
    //     if (res.state == 0) {
    //       this.roleList = res.value;
    //     } else {
    //       this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //         confirmButtonText: this.$t('pop_up.Determine')
    //       });
    //     }
    //   });
    // },
    // 重置表单
    reset() {
      this.formInline = { selectType: 1 };
      this.timeReset();
      this.searchData();
    },
    // 时间重置
    timeReset() {
      const startTime = this.$moment()
        .subtract(1, "years")
        .format("YYYY-MM-DD");
      const endTime = this.$moment().format("YYYY-MM-DD");
      this.timeArray = [startTime, endTime];
    },
  },
  // beforeDestroy() {
  //   sessionStorage.searchData = JSON.stringify(this.formInline);
  //   sessionStorage.searchFlag = 1;
  // },
  activated() {
    this.searchData();
  },
  created() {
    // if (sessionStorage.searchFlag == 1) {
    //   this.formInline = JSON.parse(sessionStorage.searchData);
    // }
    this.timeReset();
    this.searchData();
    // this.getRoleList();
    // let xxx = utils.portalHasPage("/operatorAdministratorsManage");
    // this.buttonX = xxx;
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    padding: 22px 22px 0;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
